<template>
    <div class="introduction">
        <div class="introduction__line-area">
            <v-row class="my-0 row--lg h-100">
                <v-col cols="4" class="py-0 h-100">
                    <div class="introduction__line introduction__line--1">
                        <p class="introduction__line__caption font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase" >Lawfirm Taesng</p>
                    </div>
                </v-col>
                <v-col offset-md="4" cols="4" class="py-0 h-100">
                    <div class="introduction__line introduction__line--2">
                        <p class="introduction__line__caption font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase" >Lawfirm Taesng</p>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="introduction__title mb-20px mb-md-0">
            <v-row no-gutters align="end">
                <v-col cols="12" md="">         
                    <tit-wrap-primary dark class="pb-md-0">
                        변호사 소개
                        <template #TitAdd>Introduction</template>
                    </tit-wrap-primary>
                </v-col>
                <v-col cols="12" md="auto">
                    <div data-aos="fade-up" data-aos-delay="200" class="introduction-contents__btn-group">
                        <v-row align="center" justify="end">
                            <v-col cols="auto">
                                <btn-arrow-primary prev color="grey lighten-1" class="swiper-btn swiper-btn-prev" />
                            </v-col>
                            <v-col cols="auto" class="d-md-none">
                                <span class="line"></span>
                            </v-col>
                            <v-col cols="auto">
                                <btn-arrow-primary next color="grey lighten-1" class="swiper-btn swiper-btn-next" />
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div data-aos="fade-up" class="introduction__slide">
            <swiper ref="swiperIntroduction" :options="swiperOptionIntroduction" class="swiper swiper--introduction">
                <swiper-slide v-for="(item, index) in slide" :key="index">
                    <div class="introduction-item">
                        <div class="introduction-item__image">
                            <div class="introduction-item__image__inner image-card" :style="'background-image: url(/images/main/lawyer/'+item.title+'.jpg)'"></div>
                            <div class="introduction-item__image__contents pa-20px">
                                <txt class="white--text">
                                    <v-row align="center" justify="center" class="row--xxs text-center">
                                        <v-col cols="12">
                                            <p class="introduction-item__image__text introduction-item__image__text--3 font-weight-bold line-height-1 font-secondary">{{item.titleEn}}</p>
                                        </v-col>
                                        <v-col cols="auto">
                                            <h4 class="introduction-item__image__text introduction-item__image__text--1 txt--xl">{{item.title}}</h4>
                                        </v-col>
                                        <v-col cols="auto">
                                            <p class="introduction-item__image__text introduction-item__image__text--2">
                                                <span v-if="item.ceo">대표 변호사</span>
                                                <span v-else>변호사</span>
                                            </p>
                                        </v-col>
                                    </v-row>
                                </txt>
                            </div>
                        </div>
                        <div class="introduction-item__contents pt-16px pt-md-32px">
                            <txt class="white--text">
                                <v-row align="center" class="row--xxs">
                                    <v-col cols="12">
                                        <p class="font-weight-bold line-height-1 txt--light font-secondary">{{item.titleEn}}</p>
                                    </v-col>
                                    <v-col cols="auto">
                                        <h4 class="txt--xl">{{item.title}}</h4>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span v-if="item.ceo">대표 변호사</span>
                                        <span v-else>변호사</span>
                                    </v-col>
                                </v-row>
                            </txt>
                        </div>
                    </div>  
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template> 

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,

        TitWrapPrimary,
        BtnArrowPrimary,
        IconArrowPrimary,
        Tit,
        Txt,
    },
    data() {
        return {
            swiperOptionIntroduction: {
                loop: true,
                loopedSlides: 5,
                slidesPerView: 2,
                spaceBetween: 20,
                speed: 500,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: ".introduction-contents__btn-group .swiper-btn-next",
                    prevEl: ".introduction-contents__btn-group .swiper-btn-prev",
                },
                breakpoints: {
                    768:{
                        slidesPerView: 3,
                        spaceBetween: 40,
                        centeredSlides: true,
                    },
                    1024:{
                        slidesPerView: 3,
                        spaceBetween: 60,
                        centeredSlides: true,
                    },
                },
            },
            slide : [
                {title : "윤영환", titleEn : "Yoon Younghwan", ceo: true,},
                {title : "구찬회", titleEn : "Koo Chanhoe",},
                {title : "김태은", titleEn : "Kim Taeeun",},
                {title : "남덕호", titleEn : "Nam Deokho",},
                {title : "임정훈", titleEn : "lim Junghoon",},
                {title : "최유나", titleEn : "Choi Yuna", ceo: true,},
                {title : "안은경", titleEn : "An Eunkyung",},
                {title : "이준희", titleEn : "Lee Junhee",},
                {title : "정우중", titleEn : "Jung Woojoong",},
                {title : "문종하", titleEn : "Moon Jongha",},
                {title : "원혜원", titleEn : "Won Hyewon",},
                {title : "정수진", titleEn : "Jung Sujin",},
                {title : "정지은", titleEn : "Chung Jieun",},
                {title : "구경일", titleEn : "Ku Kyeongil",},
                {title : "박규훈", titleEn : "Park Kyuhoon",},
            ]
        }
    } 
}
</script>

<style lang="scss" scoped>
.introduction{

    --item-foot-height : 66px;

    position: relative;
    &__line-area{
        display: none;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    &__title{
        position: relative;
        z-index: 1;
    }
    &__slide{
        width: 100%;
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100vw;
            height: 1px;
            background-color: rgba(255, 255, 255, .2);
            top: calc(50% - (var(--item-foot-height) / 2));
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &-item{        
        &__image{
            border-radius: 999px 999px 0 0;
            overflow: hidden;
            width: 100%;
            height: 0;
            padding-top: calc((660 / 440) * 100%);
            position: relative;
            transition: 0.5s ease-out;
            &__inner,
            &__contents,
            &::before{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
            &__contents{
                background-color: rgba(0, 0, 0, .5);     
                display: flex;
                align-items: center;
                justify-content: center;  
                opacity: 0;
                visibility: hidden;
                transition: 0.2s ease-out;
            }
            &::before{
                content: "";
                display: block;
                background-image: url(/images/symbol-light.svg);
                background-position: center bottom;
                background-repeat: no-repeat;
                background-size: 100% auto;
                z-index: 1;
                opacity: 0;
                visibility: hidden;
                transition: 0.2s ease-out;
            }
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 6px;
                left: 0;
                bottom: 0;
                z-index: 2;
                background: linear-gradient(270deg,#b18a00 0,#ffea9f 50%,#b18a00);
                opacity: 0;
                visibility: hidden;
                transform: scaleX(0);
                transition: 0.2s ease-out;
            }
            &__text{
                transform: scale(1.4);
                opacity: 0;
                visibility: hidden;
                transition: 0.2s ease-out;
            }
        }
        &__contents{
            opacity: 1;
            visibility: visible;
            transition: 0.2s ease-out;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .introduction{

        --head-height : 124px;
        --item-foot-height : 96px;

        &__line-area{
            display: block;
        }
        &__line{
            position: relative;
            top: calc(var(--page-section-padding-y) * -1);
            height: calc(100% + (var(--page-section-padding-y) * 2));
            // background-color: red;
            &__caption{
                position: absolute;
                right: 8px;
            }
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 1px;
                right: 0;
                background-color: rgba(255, 255, 255, .2);
            }
            &--1{
                .introduction__line__caption{
                    top: calc(var(--page-section-padding-y) + var(--tit-wrap-padding-bottom-lg) + var(--head-height) - 20px);
                    transform: translateY(-100%);
                }
                &::before{
                    top: 0;
                    height: calc(var(--page-section-padding-y) + var(--tit-wrap-padding-bottom-lg) + var(--head-height));
                }
            }
            &--2{
                .introduction__line__caption{
                    bottom: 20px;
                }
                &::before{
                    bottom: 0;
                    height: calc(var(--page-section-padding-y) + var(--item-foot-height));
                }
            }
        }        
        &__title{
            padding-bottom: var(--tit-wrap-padding-bottom-lg);
        }
    }    
    .swiper-slide:not(.swiper-slide-active){
        .introduction{
            &-item{        
                &__image{
                    border-radius: 0;
                }
            }
        }
    }
}
@media (min-width:1024px){
    .introduction{
        --head-height : 138px;
    }
    .swiper-slide-active{
        .introduction-item:hover{  
            .introduction-item__image{
                &__contents{
                    opacity: 1;
                    visibility: visible;
                }
                &::before{
                    opacity: 1;
                    visibility: visible;
                }
                &::after{
                    opacity: 1;
                    visibility: visible;
                    transform: scaleX(1);
                    transition: 0.25s 0.7s ease-out;
                }
                &__text{
                    transform: scale(1);
                    opacity: 1;
                    visibility: visible;
                    transition: 0.5s 0.2s ease-out;
                    &--1{
                        transition-delay: 0.2s;
                    }
                    &--2{
                        transition-delay: 0.3s;
                    }
                    &--3{
                        transition-delay: 0.4s;
                    }
                }
            }
            .introduction-item__contents{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}
@media (min-width:1200px){
}

</style>